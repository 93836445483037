import { MouseEvent, useState, useEffect } from 'react';
import { ClickAwayListener, Fade, IconButton, Paper, Popper } from '@mui/material';
import styled from '@emotion/styled';
import Link from '../../Link';
import { recordClick } from '@thestudentroom/datalayer';
import { MdMoreVert } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useAppTheme } from '@thestudentroom/lib.themes';

interface PaginationToolProps {
    urlPrefix: string | null;
    totalPages: number;
    currentPage: number;
    isDisabled?: boolean;
    onPagination?: Function;
}

const StyledIconButton = styled(IconButton)`
    width: 23px;
    height: 23px;
    padding: ${(props) => props.theme.spacing(0)};
    border-radius: 4px;

    @media (min-width: 670px) {
        padding: unset;
    }
`;

const UsefulLinkText = styled.p`
    padding: ${(props) => props.theme.spacing(4, 4, 2)};
    margin: 0px;
    ${(props) => props.theme.typography.caption};
    color: ${(props) => props.theme.text.secondary};
    text-transform: none;
`;

const PaginationTool = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const PaginationToolContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 104px;
    margin-top: -${(props) => props.theme.spacing(1)};
`;

const StyledUsefulLink = styled(Link)`
    ${(props) => props.theme.typography.button};
    color: ${(props) => props.theme.text.secondary};
    padding: ${(props) => props.theme.spacing(2, 4)};
    &:last-of-type {
        padding: ${(props) => props.theme.spacing(2, 4, 4)};
    }
    &:hover {
        text-decoration: none;
        background-color: ${(props) => props.theme.button.secondaryButtonHover};
        border-radius: 0px 0px 4px 4px;
    }
`;

export const PaginationTools = ({
    urlPrefix,
    totalPages,
    currentPage,
    onPagination,
    isDisabled = false
}: PaginationToolProps) => {
    const theme = useAppTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
        recordClick({
            group: 'navigating',
            section: 'thread-tool-bar-top',
            subsection: 'pagination',
            item: 'pagination-tools',
            action: !open ? 'open' : 'close'
        });

        setAnchorEl(event.currentTarget);
        setOpen(!open);
    }

    const paginate = (event: MouseEvent<HTMLElement>, page: number) => {
        event.preventDefault();
        if (typeof onPagination !== 'undefined') {
            setTimeout(() => {
                onPagination(page);
            },0);
        } else {
            setTimeout(() => {
                recordClick({
                    group: 'navigating',
                    section: 'thread-tool-bar-top',
                    subsection: 'pagination',
                    item: `Go to page ${page}`
                });

                navigate(`${urlPrefix}&page=${page}`);
            },0);
        }
    };

    function getPageLinks() {
        var pageCounter = 10;
        var array = [];
        // If the total no. of pages > 5
        if (totalPages && totalPages > 5) {
            // Push page 1 and 5 into the array
            array.push(1, 5);
            while (pageCounter <= totalPages) {
                array.push(pageCounter);
                // If the pageCounter is over 100, push values in groups of 100
                // i.e. 100, 200, 300
                if (pageCounter >= 100) {
                    pageCounter += 100;
                } else {
                    // If the pageCounter is less than 100, push values in groups of 10
                    // i.e. 10, 20, 30
                    pageCounter += 10;
                }
            }
        }

        // If total pages less than 6, array is empty []
        // Otherwise, returns numbers in format e.g. [1, 5, 10, 20, 30 ... 100, 200]
        return array;
    }

    const pageLinks = getPageLinks();

    // Close pagination dropdown on page change
    useEffect(() => {
        setOpen(false);
    }, [currentPage]);

    return (
        <ClickAwayListener
            onClickAway={() => {
                setOpen(false);
            }}
        >
            <PaginationTool>
                <StyledIconButton
                    onClick={(e) => handleClick(e)}
                    sx={{
                        backgroundColor: open ? theme.button.secondaryButtonHover : 'transparent',
                        WebkitTapHighlightColor: open
                            ? theme.button.secondaryButtonHover
                            : 'transparent'
                    }}
                    aria-label="Click to see pagination tools"
                    disabled={isDisabled}
                    data-testid="pagination-tools"
                >
                    <MdMoreVert
                        size={20}
                        data-testid="ellipsis-icon"
                        color={theme.text.primary}
                        style={{ opacity: isDisabled ? '0.38' : '1' }}
                    />
                </StyledIconButton>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    keepMounted={true} // Always keep the children in the DOM (SEO)
                    placement={'bottom-end'}
                    transition
                    disablePortal={true}
                    modifiers={[
                        // Prevents tooltip from flipping vertically when it disappears out of viewport
                        { name: 'flip', enabled: false },
                        { name: 'preventOverflow', enabled: false }
                    ]}
                    style={{ zIndex: 1 }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <PaginationToolContainer>
                                    <UsefulLinkText>Go to page</UsefulLinkText>
                                    {pageLinks.map((page) => {
                                        return (
                                            <StyledUsefulLink
                                                key={`page-${page}`}
                                                url={`${urlPrefix}&page=${page}`}
                                                rel={
                                                    currentPage &&
                                                    // If any of the following conditions are true, add nofollow to the link
                                                    (currentPage == page || // If the current page == the number inside the pagination dropdown
                                                        (currentPage < 4 && page < 4) || // If the current page is < 4 (page 1 button is visible)
                                                        page == currentPage + 1 ||
                                                        page == currentPage - 1) // If the pagination tool number is 1 more or less than the current page, and therefore visible
                                                        ? 'nofollow'
                                                        : ''
                                                }
                                                onClick={(event) => {
                                                    paginate(event, page);
                                                }}
                                                title={`Go to page ${page}`}
                                            >
                                                {page}
                                            </StyledUsefulLink>
                                        );
                                    })}
                                </PaginationToolContainer>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </PaginationTool>
        </ClickAwayListener>
    );
};

import styled from '@emotion/styled';
import useTranslation from '../../../../hooks/useTranslation';
import { MdImageNotSupported } from 'react-icons/md';
import { useAppTheme } from '@thestudentroom/lib.themes';

const MissingAttachmentContainer = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    padding: ${(props) => props.theme.spacing(3, 7)};
    gap: ${(props) => props.theme.spacing(2)};
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    background-color: ${(props) => props.theme.palette.tertiary.light};
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    margin: ${(props) => props.theme.spacing(0)};
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: bold;
    ${(props) => props.theme.typography.body2};
    width: 90%;

    @media (min-width: 671px) {
        ${(props) => props.theme.typography.body1};
    }
`;

interface MissingAttachmentProps {
    type: 'moderation' | 'broken'
}

export default function MissingAttachment({ type }: MissingAttachmentProps) {
    const { t } = useTranslation();
    const theme = useAppTheme();

    return (
        <MissingAttachmentContainer>
            <MdImageNotSupported
                size={20}
                data-testid="broken-attachment-icon"
                color={theme.palette.primary.main}
                style={{
                    minHeight: 20,
                    minWidth: 20
                }}
            />
            {type == 'broken' ? t('bbcode.attachment-not-found') : t('bbcode.attachment-moderated')}
        </MissingAttachmentContainer>
    );
}

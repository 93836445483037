import { useEffect } from 'react';
import { Attachment } from 'types/thread';
import MissingAttachment from './MissingAttachment';
import { GET_ATTACHMENT } from '../../../api/Thread/graph';
import { useQuery as useGraphQLQuery } from '@apollo/client';
import { runtimeConfig } from '../../../runtimeConfig';
import { InlineAnchorTag } from '../../Link/styles';
import Image from '../Image';

const IMAGE_FILE_TYPES = ['bmp', 'gif', 'jpe', 'jpg', 'jpeg', 'png'];

interface AttachProps {
    attachmentId: number;
    attachments: Attachment[];
    isFirstPost: boolean;
    includeStructuredData?: boolean;
}

export function Attach({ attachmentId, attachments, isFirstPost, includeStructuredData }: AttachProps) {
    let attachment;

    // check to see if attachment belongs to current post
    attachment = attachmentId && attachments.find((attachment) => attachment.id === attachmentId);

    // skip the query if the ID is invalid or we already found the attachment
    const skipQuery = !attachmentId || !!attachment;

    // otherwise, look up attachment using the attachment ID
    const attachmentData = useGraphQLQuery(GET_ATTACHMENT, {
        variables: { attachmentId: attachmentId },
        errorPolicy: 'all',
        skip: skipQuery,
        ssr: true
    });

    useEffect(() => {
        if (!skipQuery) {
            attachmentData.refetch();
        }
    }, [skipQuery]);

    if (!attachment && !attachmentData.loading) {
        attachment =
            attachmentData.data && attachmentData.data.attachment
                ? attachmentData.data.attachment
                : null;
    }

    // if we successfully found the attachment, check the file type
    if (attachment) {
        // if the attachment has a moderation state, render the moderated attachment component
        if (attachment.state == 'moderation') {
            return <MissingAttachment type={'moderation'} />;
        }

        // if the attachment is an image, render image tags
        if (IMAGE_FILE_TYPES.includes(attachment.file_type)) {
            return (
                <Image
                    src={runtimeConfig.appDomain + attachment.url}
                    alt={attachment.file_name}
                    loading={isFirstPost ? 'eager' : 'lazy'}
                    includeStructuredData={includeStructuredData}
                />
            );
        }

        // otherwise, render anchor tags
        return (
            <InlineAnchorTag href={runtimeConfig.appDomain + attachment.url}>
                {attachment.file_name}
            </InlineAnchorTag>
        );
    }

    // if the attachment was not found, render the broken attachment component
    return <MissingAttachment type={'broken'} />;
}
